import { useRouter } from "next/router";
import React from "react";
import { FlexRow, SectionCard } from "src/components/ui/common";
import { colors } from "src/theme";
import { Body, LargeTitle } from "src/theme/typography";

const statsData: Array<{
  id: string;
  prefix: string;
  stat: { en: string; uk?: string; us?: string };
  suffix: string;
}> = [
  {
    id: "transactions",
    prefix: "process",
    stat: { en: "£8.8M+", uk: "£8.8M+", us: "$12.4M+" },
    suffix: "in transactions",
  },
  {
    id: "pet-registrations",
    prefix: "register",
    stat: { en: "720,000+" },
    suffix: "pets",
  },
  {
    id: "time-saved",
    prefix: "save time for",
    stat: { en: "15,000+" },
    suffix: "veterinary professionals",
  },
];

const Stats = () => {
  const { locale } = useRouter();
  return (
    <SectionCard>
      <LargeTitle style={{ maxWidth: 620 }}>
        Since we launched in January 2020, we&apos;ve helped&hellip;
      </LargeTitle>
      <FlexRow style={{ flexWrap: "wrap", gap: 40 }}>
        {statsData.map(({ id, prefix, stat, suffix }) => (
          <Body key={id} style={{ minWidth: 200, flex: 1 }}>
            {prefix}
            <br />
            <LargeTitle as="span" textColor={colors.main}>
              {["en-US", "en-CA"].includes(locale || "")
                ? (stat.us && stat.us) || stat.en
                : stat.en}
            </LargeTitle>
            <br />
            {suffix}
          </Body>
        ))}
      </FlexRow>
    </SectionCard>
  );
};

export default Stats;
